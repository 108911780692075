.container {
  z-index: 999;
  max-width: 400px;
  box-shadow: 4px 4px 4px rgba(107, 107, 107, 0.25);
  @apply text-mono-black p-4 rounded-lg tracking-wider
  bg-mono-white text-left border-mono-lightGrey border
  fixed bottom-2 right-2 md:right-5 md:bottom-5
  flex items-center transition-all duration-300 transform opacity-0 translate-x-96;
}

.container.active {
  @apply translate-x-0 opacity-100;
}

.container p {
  @apply text-mono-black text-cvp tracking-normal;
}

.container.pink {
  @apply border-primary-pink font-light;
}

.container.orange {
  @apply border-primary-orange font-light;
}

.container.teal {
  @apply border-primary-teal font-light;
}

.icLeft {
  @apply text-primary-teal;
}

.icLeft.pink {
  @apply text-primary-pink;
}

.icLeft.orange {
  @apply text-primary-orange;
}

.icLeft.midGrey {
  @apply text-mono-midGrey;
}