.backdrop {
  @apply fixed top-0 left-0 h-screen w-screen;
  animation: drop 0.4s normal forwards;
  z-index: 101;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.contentCentered {
  @apply flex justify-center items-center;
  margin: 15px auto;
  min-height: calc(100% - 30px);
}

.content {
  @apply bg-mono-white rounded-lg p-8 relative overflow-auto cursor-default shadow-2xl;
}

@keyframes drop {
  from {
    top: -100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
