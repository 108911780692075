@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: gotham;
    src: url("./assets/fonts/Gotham/Gotham-Book.otf") format("opentype");
    font-weight: normal;
  }

  @font-face {
    font-family: gotham;
    src: url("./assets/fonts/Gotham/Gotham-Bold.otf") format("opentype");
    font-weight: 600;
  }

  h1 {
    @apply font-gotham font-bold text-cvH1m md:text-cvH1;
  }

  h2 {
    @apply font-gotham font-bold text-cvH2m md:text-cvH2;
  }

  h3 {
    @apply font-gotham font-bold text-cvH3m md:text-cvH3;
  }

  h4 {
    @apply font-gotham font-bold text-cvH4m md:text-cvH4;
  }

  h5 {
    @apply font-gotham font-normal text-cvH5m md:text-cvH5;
  }

  p {
    @apply font-gotham font-normal text-cvpm md:text-cvp;
  }

  label {
    @apply font-gotham font-bold text-cvp;
  }

  .content {
    @apply font-gotham font-normal text-lg;
  }

  caption,
  .caption {
    @apply font-gotham font-normal text-cvCaptionMobile md:text-cvCaption;
    letter-spacing: -0.03em;
    display: block;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    @apply font-gotham;
  }

  button:disabled,
  button[disabled] {
    @apply text-mono-dust;
  }

  [data-title]:hover:after {
    @apply opacity-100 transition-opacity visible;
  }
  [data-title]:after {
    content: attr(data-title);
    @apply absolute border border-primary-orange rounded-lg
    px-2 py-1 text-xs font-light text-mono-black
    bg-mono-white left-full -bottom-full
    opacity-0 invisible w-72;
    z-index: 1000;
  }
  [data-title] {
    @apply relative;
  }
  .left[data-title]:after {
    @apply -bottom-full;
    left: -18rem;
  }

  * {
    @apply font-gotham;
    letter-spacing: -0.02em;
  }
}
