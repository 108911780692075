.loadingIcon {
  @apply text-5xl;
  animation: colorChange 2s linear infinite alternate;
}
.loadingIndicator {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

@keyframes colorChange {
  from {
    @apply text-primary-pink;
  }
  to {
    @apply text-primary-orange;
  }
}
